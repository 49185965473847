import { useCallback, useEffect, useLayoutEffect, useState } from "react";

const BREAKPOINTS_INTERVALS = [
  {
    name: "isNotInitMobile",
    query: "(max-width: 767px)",
  },
  {
    name: "isMobile",
    query: "(max-width: 767px)",
  },
  {
    name: "isTablet",
    query: "(min-width: 768px) and (max-width: 991px)",
  },
  {
    name: "isTabletOrLess",
    query: "(max-width: 991px)",
  },
  {
    name: "isSmallDesktop",
    query: "(min-width: 992px) and (max-width: 1199px)",
  },
  {
    name: "isDesktop",
    query: "(min-width: 1200px)",
  },
];

const useMedia = () => {
  const [getBreakpoints, setBreakpoints] = useState({
    isNotInitMobile: false,
    isMobile: true,
    isTablet: false,
    isTabletOrLess: false,
    isSmallDesktop: false,
    isDesktop: false,
  });

  const updateStateByKey = useCallback((key, value) => {
    setBreakpoints((prevState) => {
      if (prevState[key] !== value) {
        return { ...prevState, [key]: value };
      } else {
        return prevState;
      }
    });
  }, []);

  const iterateAllPoints = useCallback(() => {
    if (typeof window !== "undefined") {
      BREAKPOINTS_INTERVALS.forEach((media) => {
        updateStateByKey(media.name, window.matchMedia(media.query).matches);
      });
    }
  }, [updateStateByKey]);

  useLayoutEffect(() => {
    iterateAllPoints();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", iterateAllPoints);
    return () => {
      window.removeEventListener("resize", iterateAllPoints);
    };
  }, [iterateAllPoints]);

  return getBreakpoints;
};

export default useMedia;
