import { createContext } from "react";

export const MediaContext = createContext({
  isNotInitMobile: false,
  isMobile: true,
  isTablet: false,
  isTabletOrLess: false,
  isSmallDesktop: false,
  isDesktop: false,
});
