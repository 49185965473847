import React from "react";
import { MediaContext } from "../context/media-context";
import useMedia from "../../hooks/useMedia";

const MediaProvider = ({ children }) => {
  const media = useMedia();
  return (
    <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
  );
};

export default MediaProvider;
